@import '../../../../../assets/styles/colors';
@import '../../../../../assets/styles/fonts';
@import '../../../../../assets/styles/mixins';

.title_container {
  width: 100%;
  margin-top: 7vh;

  & > h1 {
    text-align: center;
    width: 50%;
    line-height: 1.2;
    font-size: 44px;
    font-weight: bold;
    color: $brand-primary;
    margin-left: 25%;
    padding-top: 2vh;
    @include media(m-device) {
      font-size: 34px;
    }

    @include media(s-device) {
      width: 80%;
      font-size: 28px;
      margin-left: 10%;
    }
  }
}

.thematics_container {
  width: 90%;
  max-width: 1280px;
  margin: 4vh auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media(s-device) {
    width: 96%;
    margin-left: 2%;
  }
}

@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/fonts';
@import '../../../../../../assets/styles/mixins';

.loading_container {
  width: 90%;
  max-width: 1280px;
  margin: 10vh auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media(s-device) {
    width: 96%;
    margin-left: 2%;
  }

  &__element {
    width: 350px;
    max-height: 400px;
    margin: 15px;
    padding: 30px 20px 20px 20px;
    border-radius: 8px;
    border: 2px solid $ui-quaternary;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: inherit;

    &__title {
      background-color: $ui-secondary-shade;
      height: 25px;
      width: 50%;
      border-radius: 8px;
    }

    &__description {
      margin-top: 15px;
      background-color: $ui-secondary-shade-secondary;
      height: 200px;
      width: 100%;
      border-radius: 8px;
    }

    &__article {
      margin-top: 15px;
      background-color: $brand-primary-shade;
      height: 20px;
      width: 40%;
      border-radius: 8px;
    }
  }
}

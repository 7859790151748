@import '../../../../../../assets/styles/colors';
@import '../../../../../../assets/styles/fonts';
@import '../../../../../../assets/styles/mixins';
@import '../../../../../../assets/styles/typography';

.thematic_container {
  cursor: pointer;
  width: 350px;
  max-height: 420px;
  margin: 15px;
  padding: 30px 20px 20px 20px;
  border-radius: 8px;
  border: solid 2px $ui-quaternary-muted;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: inherit;

  &__content {
    max-height: 300px;
    height: auto;

    & > img {
      width: auto;
      height: 38px;
      margin: 10px 0 25px 0;
    }

    &_title {
      font-size: $level5;
      font-weight: 600;
      line-height: 1.2;
      font-family: $primary-bold;
      margin-bottom: 15px;
      @include media(s-device) {
        font-size: $level6;
      }
    }

    &_description {
      height: auto;
      max-height: 176px;
      margin-bottom: 20px;

      & > img {
        width: auto;
        height: 40px;
      }

      ol {
        list-style-type: decimal;

        & > li {
          margin-top: 7px;
          list-style: unset;
          display: list-item;
          margin-left: 15px;

          & > span {
            margin-left: 7px;
          }
        }
      }

      ul, li {
        list-style: inside;
        margin-top: 5px;
      }

      b {
        font-family: $primary-bold;
      }

    }

    &_articles {
      margin-top: 35px;
      margin-bottom: 10px;
      bottom: 20px;
    }

    &_scrollable {
      width: 100%;
      overflow-y: auto;
    }
    &_scrollable::-webkit-scrollbar { width: 1px !important }
  }
}



